import React from 'react';
import styled from 'styled-components';

import palette from '../../shared/palette';
import {
  PrimaryHeading,
  SecondaryHeading,
  ParagraphText,
} from '../../shared/typography';
import { center } from '../../shared/mixins';

const About = () => {
  return (
    <Section>
      <AboutHeading>Redefining Wellness</AboutHeading>
      <SecondaryHeading>More than just a buzzword</SecondaryHeading>
      <VideoContainer>
        <AbsoluteWrapper>
          <iframe
            title="main-vid"
            height="100%"
            width="100%"
            src="https://www.youtube.com/embed/y4NAjtOqVbY"
            frameBorder="0"
          />
        </AbsoluteWrapper>
      </VideoContainer>
      <AboutText>{text}</AboutText>
    </Section>
  );
};

// youtube vid https://www.youtube.com/embed/6rqNQfn41RQ

export default About;

const text = `
    WearDuke is a campus health initiative approaching student wellness at Duke in a fresh way. 
    In the face of academic and social pressures, wellness slips the mind. But what if there was a way to allow students 
    to monitor their activity and be aware when action needs to be taken?
`;

const Section = styled.div`
  background-color: ${palette.light1};
  padding: 10rem 0rem;

  @media (max-width: 700px) {
    padding-bottom: 3rem;
  }
`;

const AboutHeading = styled(PrimaryHeading)`
  && {
    margin-bottom: 1rem;
  }
`;

const VideoContainer = styled.div`
  position: relative;
  margin: 0 auto;
  margin-bottom: 5rem;
  width: 100%;
  padding-top: 47.5%;
  max-width: 90rem;

  @media (min-width: 1050px) {
    height: 47.5rem;
    padding-top: 0;
  }

  @media (max-width: 600px) {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 55%;
  }
`;

const AbsoluteWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  padding-left: 5rem;
  padding-right: 5rem;

  @media (max-width: 600px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

const AboutText = styled(ParagraphText)`
  margin-bottom: 1rem;
  ${center};
`;
