import React from 'react';
import styled from 'styled-components';

import palette from '../../shared/palette';

const Arrow = props => {
  return (
    <ArrowOuter index={props.index} chosen={props.chosen}>
      <ArrowInner
        index={props.index}
        color={props.color}
        backgroundColor={props.backgroundColor}
        onClick={() => {
          props.changeChosen(props.index);
        }}
      >
        {props.years}
      </ArrowInner>
    </ArrowOuter>
  );
};

export default Arrow;

const ArrowOuter = styled.div`
  position: relative;

  &:first-child {
    margin-right: -4rem;
  }

  &:last-child {
    margin-left: -4rem;
  }

  &::after {
    content: '◆';
    position: absolute;
    color: ${palette.dark1};
    left: 50%;
    transform: translateX(-50%);
    top: -4rem;
    opacity: ${props => (props.chosen === props.index ? '1' : '0')};
    font-size: 3rem;
    transition: 0.2s ease;
  }

  &:hover::after {
    opacity: 1;
  }
`;

const ArrowInner = styled.div`
  cursor: pointer;
  user-select: none;
  padding: 1.25rem 7.5rem;
  font-size: 2.5rem;
  transition: 0.3s ease;
  text-align: center;
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  border-radius: ${props => (props.index === 0 ? '6px 0 0 6px' : '')};
  clip-path: ${props => {
    switch (props.index) {
      case 0:
        return 'polygon(0 0, calc(100% - 4rem) 0, 100% 50%, calc(100% - 4rem) 100%, 0 100%)';
      case 1:
      case 2:
        return 'polygon(0 0, calc(100% - 4rem) 0, 100% 50%, calc(100% - 4rem) 100%, 0 100%, 4rem 50%)';
      default:
        return 'none';
    }
  }};

  @media (max-width: 800px) {
    padding: 1.25rem 5rem;
    font-size: 2rem;
  }

  @media (max-width: 600px) {
    padding: 1.25rem 4rem;

    clip-path: ${props => {
      switch (props.index) {
        case 0:
          return 'polygon(0 0, calc(100% - 3rem) 0, 100% 50%, calc(100% - 3rem) 100%, 0 100%)';
        case 1:
        case 2:
          return 'polygon(0 0, calc(100% - 3rem) 0, 100% 50%, calc(100% - 3rem) 100%, 0 100%, 3rem 50%)';
        default:
          return 'none';
      }
    }};
  }

  @media (max-width: 400px) {
    font-size: 1.75rem;
  }
`;
