import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ParagraphText } from '../../shared/typography';

const Card = props => {
  return (
    <Container backgroundColor={props.backgroundColor} color={props.color}>
      <Title color={props.color}>{props.title}</Title>
      <Icon icon={props.icon} size="7x" />
      <Description color={props.color}>{props.description}</Description>
    </Container>
  );
};

export default Card;

const Container = styled.div`
  width: 30rem;
  display: inline-block;
  padding: 3rem 2rem;
  border-radius: 6px;
  margin: 1rem;
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color};
`;

const Title = styled(ParagraphText)`
  text-align: center;
  margin-bottom: 3rem;
  font-weight: 500;

  && {
    color: ${props => props.color};
  }
`;

const Icon = styled(FontAwesomeIcon)`
  display: block;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 3rem;
`;

const Description = styled(ParagraphText)`
  text-align: center;

  && {
    color: ${props => props.color};
  }
`;
