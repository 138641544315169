import React from 'react';
import styled from 'styled-components';

import wearDukeLogo from '../../assets/wearduke-white.png';
import landingImg from '../../assets/landing.png';

const Landing = () => {
  return (
    <Container>
      <Logo src={wearDukeLogo} alt="WearDuke Landing Logo" />
    </Container>
  );
};

export default Landing;

const Container = styled.div`
  position: relative;
  height: calc(100vh - 8rem);
  opacity: 0.8;
  background-image: url(${landingImg});
  background-size: cover;
  background-position: center;

  @media (min-width: 700px) {
    background-attachment: fixed;
  }

  @media (max-width: 700px) {
    height: 50rem;
  }
`;

const Logo = styled.img`
  width: 100%;
  max-width: 50rem;
  padding: 2rem;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
