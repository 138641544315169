import React, { Component } from 'react';
import styled from 'styled-components';
import AnimateHeight from 'react-animate-height';

import { center } from '../../shared/mixins';
import palette from '../../shared/palette';

class Question extends Component {
  state = {
    clicked: false,
    height: 0,
  };

  questionClicked = () => {
    this.setState((prevState, prevProps) => {
      return {
        clicked: !prevState.clicked,
        height: this.state.height === 0 ? 'auto' : 0,
      };
    });
  };

  render() {
    return (
      <Container>
        <QuestionContainer
          onClick={this.questionClicked}
          aria-expanded={this.state.clicked}
          aria-haspopup={true}
        >
          {this.props.question}
          <Arrow clicked={this.state.clicked}>^</Arrow>
        </QuestionContainer>
        <AnimateHeight duration={500} height={this.state.height}>
          <AnswerContainer clicked={this.state.clicked}>
            <Padding>{this.props.answer}</Padding>
          </AnswerContainer>
        </AnimateHeight>
      </Container>
    );
  }
}

export default Question;

const Container = styled.div`
  margin: 0 auto;
  max-width: 105rem;
  ${center}
  transform: none;
`;

const QuestionContainer = styled.a`
  display: block;
  position: relative;
  padding: 2rem;
  padding-right: 4rem;
  background-color: ${palette.light1};
  color: ${palette.dark1};
  font-size: 2rem;
  font-family: 'Montserrat';
  border-top: 0.5px solid ${palette.dark1};
  line-height: 20pt;
`;

const Arrow = styled.span`
  position: absolute;
  display: inline-block;
  font-size: 3rem;
  right: 4rem;
  bottom: 1rem;
  transition: 0.3s ease;
  transform: ${props =>
    props.clicked ? 'rotate(0deg) scaleY(.5)' : 'rotate(180deg) scaleY(.5)'};
  user-select: none;
`;

const Padding = styled.div`
  padding: 2rem 4rem;
`;

const AnswerContainer = styled.div`
  overflow: hidden;
  transition: 0.6s;
  height: auto;
  background-color: #ededed;
  font-size: 2rem;
  font-family: 'Montserrat';
  color: ${palette.dark1};
  line-height: 20pt;
`;
