import React, { Component } from 'react';
import styled from 'styled-components';

import palette from '../../shared/palette';
import { PrimaryHeading } from '../../shared/typography';
import Question from './Question';

class Faq extends Component {
  render() {
    const questions = faqs.map((faq, i) => {
      return <Question key={i} question={faq.question} answer={faq.answer} />;
    });

    return (
      <FaqSection>
        <FaqHeading>Top FAQs</FaqHeading>
        <div>{questions}</div>
      </FaqSection>
    );
  }
}

export default Faq;

const faqs = [
  {
    question: 'I’m really interested! How do I join?',
    answer:
      'Great! If you’re in Gilbert Addoms next year, then you’re all set! Please look forward to a letter in the mail and/or email over the summer along with instructions on how to enroll.',
  },
  {
    question: 'What is the data for?',
    answer:
      'Your data will ultimately be used to help you and fellow students to improve your own performance and better keep track of your own health. We will not analyze the data at the individual level, but instead combine the data of all participants to understand about health behaviors and develop interventions that are responsive to student needs.',
  },
  {
    question: 'Who will see my data?',
    answer:
      'Only study investigators and the study coordinator will have access to the data files. All data will be stored with a study ID number, so that your name will not be associated with your data. All data will be securely stored at Duke.',
  },
  {
    question: 'If I successfully complete the study, can I keep the wearable?',
    answer:
      'Yes, if you remain an active participant by completing at least half of the surveys and wearing the wearable regularly for the fall semester, the wearable is all yours!',
  },
  {
    question: 'Who can I contact for more information?',
    answer: [
      'We’re happy to help answer any additional questions or provide clarifications on detail. Please email us at ',
      <a
        style={{
          color: palette.dark1,
          textDecoration: 'none',
          borderBottom: `1px solid ${palette.dark1}`,
        }}
        href="mailto:wearduke@duke.edu"
      >
        wearduke@duke.edu.
      </a>,
    ],
  },
];

const FaqSection = styled.div`
  background-color: ${palette.dark1};
  padding: 10rem 0rem;
`;

const FaqHeading = styled(PrimaryHeading)`
  color: ${palette.light1};
`;
