import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBed,
  faChartBar,
  faDumbbell,
} from '@fortawesome/free-solid-svg-icons';

import Page from '../shared/Page';
import Landing from './Landing/Landing';
import About from './About/About';
import WearableBreak from './WearableBreak/WearableBreak';
import Wearables from './Wearables/Wearables';
import Plan from './Plan/Plan';
import Stories from './Stories/Stories';
import Faq from './Faq/Faq';

// add font awesome icons
library.add(faBed);
library.add(faDumbbell);
library.add(faChartBar);

const HomePage = () => {
  return (
    <Page>
      <Landing />
      <About />
      <WearableBreak />
      <Wearables />
      <Plan />
      <Stories />
      <Faq />
    </Page>
  );
};

export default HomePage;
