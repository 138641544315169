import React from 'react';
import styled from 'styled-components';

import { center } from '../../shared/mixins';
import palette from '../../shared/palette';
import { PrimaryHeading, ParagraphText } from '../../shared/typography';
import Card from './Card';

const Wearables = () => {
  const cards = cardData.map((card, i) => {
    return (
      <Card
        title={card.title}
        description={card.description}
        backgroundColor={card.backgroundColor}
        color={card.color}
        icon={card.icon}
        key={i}
      />
    );
  });

  return (
    <WearablesSection>
      <PrimaryHeading>Meet the Wearables</PrimaryHeading>
      <WearablesText>{text1}</WearablesText>
      <CardContainer>{cards}</CardContainer>
      <WearablesText>{text2}</WearablesText>
    </WearablesSection>
  );
};

export default Wearables;

const text1 = `
    The wearable device market has exploded in recent years. Millions of people are tracking
    their steps, heart rate, sleep and more to make informed decisions about their health.
`;

const text2 = `
    Some of the popular commercial brands include Apple, FitBit, Garmin, and Polar.
    WearDuke wants to use this technology and the excitement surrounding it to get incoming freshman interested in learning
    about the importance of sleep and activity on their physical and mental health and academic achievement.
`;

const cardData = [
  {
    title: 'Track Sleep',
    description: 'Understand the quantity and quality of your sleep',
    backgroundColor: palette.dark1,
    color: palette.light1,
    icon: 'bed',
  },
  {
    title: 'Record Activity',
    description:
      'Create healthy habits by tracking steps and logging diet and exercise',
    backgroundColor: palette.dark2,
    color: palette.light1,
    icon: 'dumbbell',
  },
  {
    title: 'Visualize Progress',
    description: 'Become aware of trends in your health and take action',
    backgroundColor: palette.light2,
    color: palette.dark1,
    icon: 'chart-bar',
  },
];

const WearablesSection = styled.div`
  background-color: ${palette.light1};
  margin-bottom: -10rem;
  padding: 10rem 0rem;
  padding-bottom: 20rem;

  @media (max-width: 700px) {
    padding-top: 3rem;
  }
`;

const WearablesText = styled(ParagraphText)`
  transform: translateX(1rem);
  ${center}
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;
  width: 100%;
  margin-top: 5rem;
  margin-bottom: 5rem;
`;
