import styled from 'styled-components';

import { center } from './mixins';
import palette from './palette';

export const H1Heading = styled.h1`
  margin: 0 auto;
  margin-bottom: 7rem;
  font-size: 4rem;
  font-weight: 200;
  text-align: center;
  letter-spacing: 2px;
  color: ${palette.dark1};
  font-family: 'Montserrat', sans-serif;
`;

export const PrimaryHeading = styled.h2`
  margin: 0 auto;
  margin-bottom: 7rem;
  font-size: 4rem;
  font-weight: 200;
  text-align: center;
  letter-spacing: 2px;
  color: ${palette.dark1};
  font-family: 'Montserrat', sans-serif;
`;

export const SecondaryHeading = styled.h3`
  margin: 0 auto;
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 7rem;
  color: ${palette.dark2};
  font-weight: 500;
  letter-spacing: 1px;
  font-family: 'Montserrat', sans-serif;
`;

export const ParagraphText = styled.p`
  color: ${palette.dark1};
  font-size: 2rem;
  line-height: 30pt;
  letter-spacing: 1px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
`;

export const CenteredParagraphText = styled(ParagraphText)`
  ${center}
`;
