import React from 'react';
import styled from 'styled-components';

import { center } from '../../shared/mixins';
import palette from '../../shared/palette';
import { ParagraphText } from '../../shared/typography';

const Story = props => {
  return (
    <Container>
      <ImgContainer>
        <Img pic={props.pic} />
      </ImgContainer>
      <TextContainer>
        <Title>{props.title}</Title>
        <Summary>{props.summary}</Summary>
        <CenterWrapper>
          <Link href={props.url} target="_blank">
            Read More
            <span>&#10095;</span>
          </Link>
        </CenterWrapper>
      </TextContainer>
    </Container>
  );
};

export default Story;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  ${center}
  transform: none;

  &:not(:last-child) {
    margin-bottom: 6rem;
  }
`;

const ImgContainer = styled.div`
  position: relative;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  overflow: hidden;
  flex-basis: 45%;
  padding-top: 27.5%;

  @media (max-width: 800px) {
    flex-basis: 100%;
    margin-bottom: 5rem;
    padding-top: 60%;
  }
`;

const Img = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-position: center;
  background-image: ${props => `url(${props.pic})`};
`;

const TextContainer = styled.div`
  flex-basis: 45%;
  display: inline-block;
  margin-left: 5rem;

  @media (max-width: 800px) {
    flex-basis: 100%;
    margin-left: 0rem;
  }
`;

const Title = styled.h1`
  color: ${palette.dark1};
  margin-bottom: 2rem;
  text-align: left;
  font-weight: 550;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 1px;
  font-size: 2rem;
  font-weight: 600;

  @media (max-width: 800px) {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 3rem;
  }
`;

const Summary = styled(ParagraphText)`
  color: ${palette.dark2};
  margin-bottom: 5rem;
  line-height: 22.5pt;

  @media (max-width: 800px) {
    margin-bottom: 3rem;
  }
`;

const CenterWrapper = styled.div`
  text-align: left;

  @media (max-width: 800px) {
    text-align: center;
  }
`;

const Link = styled.a`
  cursor: pointer;
  display: inline-block;
  padding: 1rem 5rem;
  border: 1px solid ${palette.dark2};
  color: ${palette.dark2};
  transition: 0.3s ease;
  border-radius: 6px;
  font-size: 1.5rem;
  font-weight: 200;
  letter-spacing: 0.5px;
  text-decoration: none;
  position: relative;
  user-select: none;

  span {
    color: ${palette.dark2};
    position: absolute;
    right: 2rem;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s ease;
  }

  &:hover {
    color: ${palette.light1};
    background-color: ${palette.dark2};
  }

  &:hover span {
    color: ${palette.light1};
  }
`;
