import React from 'react';

import Layout from '../components/layout';
import HomePage from '../HomePage/HomePage';

export default () => (
  <Layout>
    <HomePage />
  </Layout>
);
