import React from 'react';
import styled from 'styled-components';

import wearableImg from '../../assets/zoom.png';
import palette from '../../shared/palette';

const WearableBreak = () => {
  return (
    <Container>
      <Wearable />
    </Container>
  );
};

export default WearableBreak;

const Container = styled.div`
  background-color: white;
  height: 40rem;

  @media (max-width: 700px) {
    height: auto;
  }
`;

const Wearable = styled.div`
  height: 30rem;
  background-color: ${palette.light1};

  background-image: url(${wearableImg});
  background-position: center !important;
  background-repeat: no-repeat;
  background-size: contain;

  @media (min-width: 700px) {
    height: 40rem;
    background-attachment: fixed;
    background-size: cover;
    width: 100%;
    background-color: white;
  }
`;
