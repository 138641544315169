import styled from 'styled-components';
import { Link } from 'gatsby';

import palette from '../palette';

export const HamburgerContainer = styled.div`
  z-index: 3;
  position: absolute;
  display: inline-block;
  right: 5rem;
  top: 3.3rem;
  height: 1.8rem;
  width: 3rem;

  @media (max-width: 600px) {
    right: 3rem;
  }
`;

export const Hamburger = styled.span`
  position: relative;
  top: 0.8rem;
  background-color: ${props =>
    props.hamburgerClicked ? 'transparent' : 'gray'};

  &,
  &::before,
  &::after {
    width: 3rem;
    height: 2px;
    display: inline-block;
    transition: all 0.2s;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    background-color: gray;
  }

  &::before {
    top: ${props => (props.hamburgerClicked ? '0rem' : '-0.8rem')};
    transform: ${props => (props.hamburgerClicked ? 'rotate(135deg)' : 'none')};
  }
  &::after {
    top: ${props => (props.hamburgerClicked ? '0rem' : '0.8rem')};
    transform: ${props =>
    props.hamburgerClicked ? 'rotate(-135deg)' : 'none'};
  }
`;

export const Dropdown = styled.nav`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  transform: ${props =>
    props.hamburgerClicked ? 'translateY(8.2rem)' : 'translateY(-100%)'};
  display: flex;
  flex-direction: column;
  transition: .5s ease;
  z-index: 0;
`;

export const DropdownLink = styled.p`
  display: inline-block;
  border-bottom: 2px solid transparent;
  padding: 0.25rem 0rem;
`;

export const DropdownItem = styled(Link)`
  :visited,
  :link {
    color: ${palette.dark1};
    text-decoration: none;
  }

  :first-child {
    border-top: 0.5px solid ${palette.dark1};
  }

  width: 100%;
  background-color: white;
  display: inline-block;
  text-align: center;
  padding: 1.5rem 0rem;
  font-size: 1.5rem;
  border-bottom: 0.5px solid ${palette.dark1};
  transition: border-bottom 0.3s ease;
  letter-spacing: 1px;
  user-select: none;
`;

export const DropdownVeil = styled.div`
  position: absolute;
  background-color: white;
  width: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  height: 8rem;
`;

export const MobileEnrollButton = styled.div`
  width: 100%;
  background-color: ${palette.dark2};
  padding: 2rem 0rem;
  text-align: center;
  font-size: 1.5rem;
  letter-spacing: 1px;
  user-select: none;
  color: white;
  text-decoration: none;

  &:link,
  &:visited {
    text-decoration: none;
  }
`;
