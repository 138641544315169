import React from 'react';
import styled from 'styled-components';
import ReactCSSTransitionReplace from 'react-css-transition-replace';

import { center } from '../../shared/mixins';
import { ParagraphText } from '../../shared/typography';

const Description = props => {
  return (
    <Container>
      <ReactCSSTransitionReplace
        transitionName="fade-wait"
        transitionEnterTimeout={800}
        transitionLeaveTimeout={400}
      >
        <PlanText key={props.index}>{props.description}</PlanText>
      </ReactCSSTransitionReplace>
    </Container>
  );
};

export default Description;

const Container = styled.div`
  position: relative;
  ${center}
`;

const PlanText = styled(ParagraphText)`
  &.fade-wait-leave {
    opacity: 1;
  }

  &.fade-wait-leave.fade-wait-leave-active {
    opacity: 0;
    transition: 0.2s ease-in;
  }

  &.fade-wait-enter {
    opacity: 0;
  }

  &.fade-wait-enter.fade-wait-enter-active {
    opacity: 1;
    transition: opacity 0.2s ease-in 0.3s;
  }

  &.fade-wait-height {
    transition: height 0.2s ease-in-out 0.2s;
  }
`;
