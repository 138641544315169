import React, { Component, Fragment } from 'react';
import styled from 'styled-components';

import palette from '../../shared/palette';
import Arrow from './Arrow';
import Description from './Description';
import { PrimaryHeading } from '../../shared/typography';

class Plan extends Component {
  state = {
    chosen: 0,
  };

  changeChosen = index => {
    this.setState({
      chosen: index,
    });
  };

  render() {
    const arrows = arrowsData.map((arrow, i) => {
      return (
        <Arrow
          key={i}
          index={i}
          chosen={this.state.chosen}
          years={arrow.years}
          color={arrow.color}
          backgroundColor={arrow.backgroundColor}
          changeChosen={this.changeChosen}
        />
      );
    });

    return (
      <Fragment>
        <Container>
          <PrimaryHeading>Our Plan</PrimaryHeading>
          <ArrowContainer>{arrows}</ArrowContainer>
          <Description
            index={this.state.chosen}
            description={arrowsData[this.state.chosen].description}
          />
        </Container>
        <Stripe1 />
        <Stripe2 />
      </Fragment>
    );
  }
}

export default Plan;

const arrowsData = [
  {
    years: '2019-2020',
    description: `The first pilot will be launched in a single residence hall. Students
            will be invited to enroll in WearDuke, obtain their wearable and begin to
            measure and track their daily activity and sleep patterns.`,
    backgroundColor: palette.dark1,
    color: palette.light1,
  },
  {
    years: '2020-2021',
    description: `The second pilot will be launched in two residence halls. Again, students will
            be invited to enroll in WearDuke, obtain their wearable and begin to measure 
            and track their daily activity and sleep patterns.\n
            Participants will be able to establish sleep goals, learn about the risks associated
            with unhealthy behavior, and access tips for getting an appropriate amount of
            sleep. Students will be alerted to various courses of action tailored to their
            activity, sleep habits, and survey responses (e.g. workshop on beating stress, 
            yoga class, walking groups, etc.).`,
    backgroundColor: palette.dark2,
    color: palette.light1,
  },
  {
    years: '2021-2022',
    description: `We will offer all students in the incoming freshman class of 
            2025 participation in WearDuke!`,
    backgroundColor: palette.light1,
  },
];

const Container = styled.div`
  clip-path: polygon(0 6vw, 100% 0, 100% 100%, 0 calc(100% - 6vw));
  padding: 17.5rem 0rem;
  background-color: ${palette.light2};
  margin-bottom: -1.8vw;
`;

const ArrowContainer = styled.div`
  margin: 0 auto;
  justify-content: center;
  display: flex;
  margin-bottom: 5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

const Stripe1 = styled.div`
  width: 100%;
  transform: skewY(3.5deg);
  background-color: ${palette.light2};
  height: 5rem;
  margin-bottom: 5rem;
`;

const Stripe2 = styled.div`
  width: 100%;
  transform: skewY(3.5deg);
  background-color: ${palette.light2};
  height: 1vw;
`;
