import React, { Component } from 'react';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import { Link } from 'gatsby';

import palette from '../palette';
import logo from '../../assets/wearduke.png';
import {
  HamburgerContainer,
  Hamburger,
  Dropdown,
  DropdownItem,
  MobileEnrollButton,
  DropdownVeil,
  DropdownLink,
} from './Mobile';
import { NavItems, NavItem, DesktopEnrollButton } from './Desktop';
import './active.css';

class Navbar extends Component {
  state = {
    transparent: false,
    hamburgerClicked: false,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true);
  }

  handleScroll = e => {
    const height = this.refs.navbar.clientHeight;
    if (window.pageYOffset >= height) {
      this.setState({
        transparent: true,
      });
    } else {
      this.setState({
        transparent: false,
      });
    }
  };

  clickedHamburger = () => {
    this.setState((prevState, prevProps) => ({
      hamburgerClicked: !prevState.hamburgerClicked,
    }));
  };

  render() {
    const links = linksData.map((link, i) => {
      return (
        <NavItem
          key={i}
          to={link.url}
          page={this.props.page}
          activeStyle={desktopActiveStyle}
          partiallyActive={link.url !== '/'} // don't want home page to be partially active
        >
          {link.name}
        </NavItem>
      );
    });

    const dropdowns = linksData.map((link, i) => {
      return (
        <DropdownItem
          key={i}
          to={link.url}
          currPage={this.props.page}
          page={link.url}
          activeClassName="active"
          partiallyActive={link.url !== '/'}
        >
          <DropdownLink>{link.name}</DropdownLink>
        </DropdownItem>
      );
    });

    return (
      <Container
        ref="navbar"
        transparent={this.state.transparent}
        onWindowScroll={this.handleScroll}
        hamburgerClicked={this.state.hamburgerClicked}
      >
        <LogoBox>
          <Link to="/">
            <Img src={logo} alt="WearDuke Homepage" />
          </Link>
        </LogoBox>
        <MediaQuery query="(min-width: 1051px)">
          <NavItems>
            {links}
            {/* <a href="/enroll">
              <DesktopEnrollButton> Enroll Now </DesktopEnrollButton>
            </a> */}
          </NavItems>
        </MediaQuery>
        <MediaQuery query="(max-width: 1050px)">
          <HamburgerContainer onClick={this.clickedHamburger}>
            <div style={{ position: 'relative' }}>
              <Hamburger hamburgerClicked={this.state.hamburgerClicked}>
                &nbsp;
              </Hamburger>
            </div>
          </HamburgerContainer>
          <Dropdown hamburgerClicked={this.state.hamburgerClicked}>
            {dropdowns}
            {/* <a href="/enroll" style={{ textDecoration: 'none' }}>
              <MobileEnrollButton>Enroll Now</MobileEnrollButton>
            </a> */}
          </Dropdown>
          <DropdownVeil />
        </MediaQuery>
      </Container>
    );
  }
}

/*
  put under {links}

  <Link to="/participants/#steps">
    <DesktopEnrollButton>Enroll Now</DesktopEnrollButton>
  </Link>

*/

/*
  put under {dropdowns}

  <Link to="/participants/#steps" style={{ textDecoration: 'none' }}>
    <MobileEnrollButton>Enroll Now</MobileEnrollButton>
  </Link>
*/

export default Navbar;

const linksData = [
  {
    name: 'Home',
    url: '/',
  },
  {
    name: 'Participant Info',
    url: '/participants',
  },
  {
    name: 'Initiative Details',
    url: '/initiative',
  },
  {
    name: 'About Us',
    url: '/about-us',
  },
  {
    name: 'FAQ',
    url: '/faq',
  },
];

// style for the active link
const desktopActiveStyle = {
  borderBottom: `2px solid ${palette.dark1}`,
};

// const mobileActiveStyle = {
//   border: `2px solid ${palette.dark1}`,
// };

// use html5 elements
const Container = styled.header`
  position: fixed;
  top: 0;
  padding: 1rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000; /* ensures navbar on top */
  width: 100vw;
  background-color: white;
  transition: opacity 0.6s ease;

  &:hover {
    opacity: 1;
  }
`;

const LogoBox = styled.div`
  z-index: 4;
`;

const Img = styled.img`
  height: 6rem;
`;
