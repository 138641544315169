import React from 'react';
import styled from 'styled-components';

import palette from '../../shared/palette';
import { PrimaryHeading } from '../../shared/typography';
import Story from './Story';
import coffeeImg from '../../assets/coffee_story.jpg';
import skyImg from '../../assets/sky_story.jpg';
import studentImg from '../../assets/students_story.jpg';

const Stories = () => {
  const stories = storiesData.map((story, i) => {
    return (
      <Story
        title={story.title}
        summary={story.summary}
        url={story.url}
        pic={story.pic}
        key={i}
      />
    );
  });

  return (
    <StoriesSection>
      <PrimaryHeading>Why should we care?</PrimaryHeading>
      {stories}
    </StoriesSection>
  );
};

export default Stories;

const storiesData = [
  {
    title: 'The Secrets of Sleep',
    summary: 'Why do we need it, and are we getting enough...',
    url:
      'https://www.newyorker.com/magazine/2017/10/23/the-secrets-of-sleep?mbid=social_facebook&utm_social-type=owned&utm_source=facebook&utm_brand=tny&utm_medium=social',
    pic: skyImg,
  },
  {
    title: 'Bribed to Sleep',
    summary: 'Students at Baylor University were offered extra credit if...',
    url:
      'https://www.insidehighered.com/news/2018/12/04/baylor-studies-find-students-perform-better-tests-after-eight-hours-sleep#.XAZUmKsL44c.email',
    pic: studentImg,
  },
  {
    title: 'Go to Bed!',
    summary:
      'Brain researchers warn that lack of sleep is a public health crisis...',
    url:
      'https://www.washingtonpost.com/national/health-science/go-to-bed-brain-researchers-warn-that-lack-of-sleep-is-a-public-health-crisis/2019/01/24/bbc61562-0a1b-11e9-85b6-41c0fe0c5b8f_story.html?noredirect=on&utm_term=.320e5fbda4e0',
    pic: coffeeImg,
  },
];

const StoriesSection = styled.div`
  background-color: ${palette.light1};
  margin-top: -20rem;
  padding: 10rem 0rem;
  padding-top: 30rem;
`;
