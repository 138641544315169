import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

// images and svgs
import dukeLogo from '../../assets/university-logo.png';
import wearDukeLogo from '../../assets/wearduke-white.png';
import facebookLogo from '../../assets/icons/facebook.svg';
import instagramLogo from '../../assets/icons/instagram.svg';
import youtubeLogo from '../../assets/icons/youtube.svg';

const Footer = () => {
  return (
    <Container>
      <TextContainer>
        <FooterText>Phone: (919) 684-0325</FooterText>
        <FooterText>
          Email:&nbsp;
          <Email href="mailto:wearduke@duke.edu">wearduke@duke.edu</Email>
        </FooterText>
        <SocialMediaLinkContainer>
          <FooterText>
            <SocialMediaLink
              href="https://www.facebook.com/WearDuke-334265310771173"
              target="_blank"
            >
              Facebook
              <img alt="find us on facebook" src={facebookLogo}></img>
            </SocialMediaLink>
          </FooterText>
          <FooterText style={{ margin: '0rem 2rem' }}>
            <SocialMediaLink
              href="https://www.instagram.com/gowearduke/"
              target="_blank"
            >
              Instagram
              <img alt="find us on instagram" src={instagramLogo}></img>
            </SocialMediaLink>
          </FooterText>
          <FooterText>
            <SocialMediaLink
              href="https://www.youtube.com/channel/UCIJx5V0nB3OQqCb7Qgmwk6g"
              target="_blank"
            >
              YouTube
              <img alt="find us on youtube" src={youtubeLogo}></img>
            </SocialMediaLink>
          </FooterText>
        </SocialMediaLinkContainer>
        <FooterText>&nbsp;</FooterText>
      </TextContainer>
      <LogoContainer>
        <a href="https://duke.edu/">
          <UniversityLogo src={dukeLogo} alt="Duke logo" />
        </a>
        <Link to="/">
          <UniversityLogo src={wearDukeLogo} alt="WearDuke logo" />
        </Link>
      </LogoContainer>
    </Container>
  );
};

export default Footer;

// use html5 element
const Container = styled.footer`
  display: flex;
  padding: 7.5rem 5rem;
  background-color: rgba(0, 0, 0, 0.9);
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
  }
`;

const TextContainer = styled.div`
  @media (max-width: 500px) {
    margin-bottom: 2rem;
  }
`;

const FooterText = styled.p`
  color: white;
  font-size: 1.5rem;
  line-height: 20pt;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;

  @media (max-width: 700px) {
    text-align: center;
  }
`;

const Email = styled.a`
  :visited,
  :link {
    color: white;
    text-decoration: none;
    border-bottom: 0.5px solid white;
  }
`;

const LogoContainer = styled.div`
  margin-top: 2rem;

  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const UniversityLogo = styled.img`
  width: 15rem;
  height: auto;
  margin: 2rem;
  @media (max-width: 500px) {
    margin: 1rem;
  }
`;

const SocialMediaLinkContainer = styled.div`
  margin-top: 1rem;
  display: flex;
`;

const SocialMediaLink = styled.a`
  color: white;
  text-decoration: none;
  border-bottom: 0.5px solid white;
  visibility: hidden;
  height: 0px;
  width: 0px;
  padding: 0;

  img {
    display: block;
    visibility: visible;
    :hover {
      opacity: 0.8;
    }
  }
`;

const DukeLink = styled.a`
  color: white;
  text-decoration: none;
  border-bottom: 0.5px solid white;
`;
