import styled from 'styled-components';
import { Link } from 'gatsby';

import palette from '../palette';

export const NavItems = styled.nav`
  z-index: 3;
  display: flex;
  margin-right: 2rem;
  align-items: center;
`;

export const NavItem = styled(Link)`
  display: inline-block;
  text-decoration: none;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  font-size: 1.5rem;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s ease;
  letter-spacing: 1px;
  color: ${palette.dark1};
  user-select: none;
  margin: 0 2rem;

  &:hover {
    border-bottom: 2px solid ${palette.dark1};
  }
`;

export const DesktopEnrollButton = styled.button`
  outline: none;
  border: none;
  background-color: #ffd960;
  padding: 2rem 3rem;
  color: #005587;
  font-size: 1.5rem;
  border-radius: 6px;
  transition: 0.3s ease;
  letter-spacing: 1px;
  font-family: 'Montserrat';
  margin-left: 2rem;

  :hover {
    transform: scale(1.02);
  }

  :active {
    transform: scale(1);
  }
`;
