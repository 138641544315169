export const center = `
    margin: 0 auto;
    width: 100%;
    padding-left: 6rem;
    padding-right: 5rem;
    max-width: 105rem;

    @media(max-width: 600px) {
        padding-left: 2rem;
        padding-right: 2rem;
        transform: none;
    }
`;
