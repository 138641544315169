import React from 'react';
import styled from 'styled-components';
import Navbar from './Navbar/Navbar';
import Footer from './Footer/Footer';

/*
    Page wrapper component for header and footer
*/

function alertBarMarkup() {
  return {
    __html: `<script src='https://alertbar.oit.duke.edu/alert.html'></script>`,
  };
}

const Page = ({ children, page }) => (
  <PageContainer>
    <Navbar currPage={page} />
    <div id="alert-bar" dangerouslySetInnerHTML={alertBarMarkup()} />
    <main>{children}</main>
    <Footer />
  </PageContainer>
);

export default Page;

const PageContainer = styled.div`
  position: relative;
  padding-top: 8rem;
`;
